import { withCompController } from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  DropDownMenuControllerProps,
  DropDownMenuStateRefs,
  IDropDownMenuMapperProps,
} from '../DropDownMenu.types';

const compController = withCompController<
  IDropDownMenuMapperProps,
  DropDownMenuControllerProps,
  DropDownMenuStateRefs
>(({ stateValues, mapperProps }) => {
  const { currentUrl, pageId, reportBi } = stateValues;
  const {
    compId,
    language,
    pagesMap,
    shouldReportAnalyticsElementsClicks,
    ...restMapperProps
  } = mapperProps;

  const reportBiOnItemClick: DropDownMenuControllerProps['reportBiOnItemClick'] =
    item => {
      const { link, label } = item;

      tryReportAnalyticsClicksBi(reportBi, {
        link,
        language,
        shouldReportAnalyticsElementsClicks,
        element_id: compId,
        elementTitle: label,
        pagesMetadata: { pagesMap, pageId },
        elementType: mapperProps.fullNameCompType,
        elementGroup: AnalyticsClicksGroups.MenuAnchor,
      });
    };

  return {
    ...restMapperProps,
    currentUrl,
    reportBiOnItemClick,
  };
});

export default compController;
